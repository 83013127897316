import { scheduleHandler } from './../../../../shared/components/helpers/datePickerHandlers';
import { DATE_API_FORMAT } from '@/utils/date.util';
import moment from 'moment';

import { TopTableHeaders } from '../../models/cellRender.model';
import { CellContentType } from '@/shared/components/table/models';
import { DropLiveType, LocationType } from '@/shared/models/woSchedule.model';

const dropLiveOptions = [
  { value: 'DROP', text: 'Drop' },
  { value: 'LIVE', text: 'Live' }
];

const detailPart: TopTableHeaders = {
  key: 'detail',
  label: '',
  opportunityСhange: false,
  maxColspan: 3,
  minColspan: 1
};

const equipmentPart: TopTableHeaders = {
  key: 'eq',
  label: 'Equipment',
  class: 'equipment-color',
  opportunityСhange: true,
  collapsed: false,
  maxColspan: 2,
  minColspan: 1
};

const infoPart: TopTableHeaders = {
  key: 'info',
  label: 'Info',
  class: 'info-color',
  opportunityСhange: true,
  collapsed: false,
  maxColspan: 3,
  minColspan: 1
};

const locationPart: TopTableHeaders = {
  key: 'loc',
  label: 'Location',
  class: 'location-color',
  opportunityСhange: true,
  collapsed: false,
  maxColspan: 3,
  minColspan: 1
};

const geofencePart: TopTableHeaders = {
  key: 'geo',
  label: 'Geofence',
  class: 'geofence-color',
  opportunityСhange: true,
  collapsed: false,
  maxColspan: 3,
  minColspan: 1
};

const savePart: TopTableHeaders = {
  key: 'save',
  label: '',
  opportunityСhange: false,
  maxColspan: 2,
  minColspan: 1
};

export const VanTableTopTypes: { [key: string]: TopTableHeaders[] } = {
  unavailable: [
    { ...detailPart },
    { ...equipmentPart },
    { ...infoPart },
    { ...locationPart },
    {
      key: 'eta',
      label: '',
      opportunityСhange: false,
      maxColspan: 3,
      minColspan: 1
    },
    {
      key: 'sch',
      label: 'Schedule',
      class: 'schedule-color',
      opportunityСhange: true,
      collapsed: false,
      maxColspan: 6,
      minColspan: 1
    },
    { ...savePart }
  ],

  available: [
    { ...detailPart },
    { ...equipmentPart },
    { ...infoPart },
    { ...locationPart },
    {
      key: 'eta',
      label: '',
      opportunityСhange: false,
      maxColspan: 1,
      minColspan: 1
    },
    {
      key: 'sch',
      label: 'Schedule',
      class: 'schedule-color',
      opportunityСhange: true,
      collapsed: false,
      maxColspan: 7,
      minColspan: 1
    },
    { ...savePart }
  ],

  delivery: [
    { ...detailPart },
    { ...equipmentPart },
    { ...infoPart },
    { ...locationPart },
    { ...geofencePart },
    {
      key: 'sch',
      label: 'Schedule',
      class: 'schedule-color',
      opportunityСhange: true,
      collapsed: false,
      maxColspan: 10,
      minColspan: 1
    },
    { ...savePart }
  ],

  return: [
    { ...detailPart },
    { ...equipmentPart },
    { ...infoPart },
    { ...locationPart },
    { ...geofencePart },
    {
      key: 'sch',
      label: 'Schedule',
      class: 'schedule-color',
      opportunityСhange: true,
      collapsed: false,
      maxColspan: 10,
      minColspan: 1
    },
    {
      key: 'save',
      label: '',
      opportunityСhange: false,
      maxColspan: 3,
      minColspan: 1
    }
  ]
};

const detailHeaderPart = [
  { key: 'number', label: 'NO', tdClass: 'min-w-20' },
  { key: 'orderNumber', label: 'WO #', tdClass: 'min-w-100' },
  { key: 'billTo', label: 'Bill To', tdClass: 'min-w-80' }
];

const equipmentHeaderPart = [
  { key: 'trailNumber', label: 'Trailer #', tdClass: 'min-w-100' },
  {
    key: 'containerType',
    label: 'Type',
    rendererKey: 'eq',
    visible: true,
    tdClass: 'min-w-100'
  }
];

const infoHeaderPart = [
  { key: 'referenceNumber', label: 'REF', tdClass: 'min-w-100' },
  {
    key: 'poNumber',
    label: 'PO',
    rendererKey: 'info',
    visible: true,
    tdClass: 'min-w-100'
  },
  {
    key: 'masterBillOfLading',
    label: 'M B/L',
    rendererKey: 'info',
    visible: true,
    tdClass: 'min-w-100'
  }
];

const locationHeaderPart = [
  { key: 'pulloutLocation', label: 'P/OUT', tdClass: 'min-w-100' },
  {
    key: 'deliveryLocation',
    label: 'DILV',
    rendererKey: 'loc',
    visible: true,
    tdClass: 'min-w-100'
  },
  {
    key: 'returnLocation',
    label: 'RTN',
    rendererKey: 'loc',
    visible: true,
    tdClass: 'min-w-100'
  }
];

const geofenceHeaderPart = [
  { key: 'originGeoferce', label: 'Orig', tdClass: 'min-w-100' },
  {
    key: 'currGeoferce',
    label: 'Curr',
    rendererKey: 'geo',
    visible: true,
    tdClass: 'min-w-100'
  },
  {
    key: 'dest',
    label: 'Dest',
    rendererKey: 'geo',
    visible: true,
    tdClass: 'min-w-80'
  }
];

const saveHeaderPart = [
  {
    key: 'save',
    label: 'Save',
    content: {
      type: CellContentType.Button,
      label: 'Save'
    },
    tdClass: 'min-w-60'
  },
  {
    key: 'remarks',
    label: 'Remark',
    content: {
      type: CellContentType.Text,
      skipValidation: true
    }
  }
];

function getDay(schedule) {
  return schedule
    ? moment(schedule, DATE_API_FORMAT).format('MM/DD/YY')
    : schedule;
}

function getTime(schedule) {
  return schedule
    ? moment(schedule, DATE_API_FORMAT).format('hh:mm')
    : schedule;
}

export const VanTableTypes = {
  unavailable: [
    ...detailHeaderPart,
    ...equipmentHeaderPart,
    ...infoHeaderPart,
    ...locationHeaderPart,

    { key: 'eta', label: 'ETA', formatter: getDay, tdClass: 'min-w-90' },
    {
      key: 'avaDsc',
      label: 'DSC',
      content: {
        type: CellContentType.Checkbox
      },
      tdClass: 'min-w-40'
    },
    {
      key: 'avaOth',
      label: 'OTH',
      content: {
        type: CellContentType.Checkbox
      },
      tdClass: 'min-w-40'
    },
    {
      key: 'pulloutSchedule',
      label: 'P/OUT S Date',
      formatter: getDay,
      handler: scheduleHandler,
      content: {
        type: CellContentType.Date,
        skipValidation: true
      },
      tdClass: 'min-w-130'
    },
    {
      key: 'pulloutScheduleTime',
      label: 'Time',
      formatter: getTime,
      rendererKey: 'sch',
      visible: true,
      content: {
        type: CellContentType.Time,
        skipValidation: true
      },
      tdClass: 'min-w-130'
    },
    {
      key: 'pulloutDriver',
      label: 'DRV ID',
      rendererKey: 'sch',
      visible: true,
      content: {
        type: CellContentType.Driver,
        locationType: LocationType.PULLOUT,
        skipValidation: true
      },
      tdClass: 'min-w-130'
    },
    {
      key: 'deliverySchedule',
      label: 'DILV S Date',
      rendererKey: 'sch',
      visible: true,
      formatter: getDay,
      handler: scheduleHandler,
      content: {
        type: CellContentType.Date,
        skipValidation: true
      },
      tdClass: 'min-w-130'
    },
    {
      key: 'deliveryScheduleTime',
      label: 'Time',
      formatter: getTime,
      rendererKey: 'sch',
      visible: true,
      content: {
        type: CellContentType.Time,
        skipValidation: true
      },
      tdClass: 'min-w-130'
    },
    {
      key: 'deliveryDriver',
      label: 'DRV ID',
      rendererKey: 'sch',
      visible: true,
      content: {
        type: CellContentType.Driver,
        locationType: LocationType.DELIVERY,
        skipValidation: true
      },
      tdClass: 'min-w-130'
    },

    ...saveHeaderPart
  ],

  available: [
    ...detailHeaderPart,
    ...equipmentHeaderPart,
    ...infoHeaderPart,
    ...locationHeaderPart,

    { key: 'eta', label: 'ETA', formatter: getDay, tdClass: 'min-w-90' },

    {
      key: 'pulloutSchedule',
      label: 'P/OUT S Date',
      handler: scheduleHandler,
      formatter: getDay,
      content: {
        type: CellContentType.Date,
        skipValidation: true
      },
      tdClass: 'min-w-130'
    },
    {
      key: 'pulloutScheduleTime',
      label: 'Time',
      formatter: getTime,
      rendererKey: 'sch',
      visible: true,
      content: {
        type: CellContentType.Time,
        skipValidation: true
      },
      tdClass: 'min-w-130'
    },
    {
      key: 'pulloutDriver',
      label: 'DRV ID',
      rendererKey: 'sch',
      visible: true,
      content: {
        type: CellContentType.Driver,
        locationType: LocationType.PULLOUT,
        skipValidation: true
      },
      tdClass: 'min-w-130'
    },
    {
      key: 'deliverySchedule',
      label: 'DILV S Date',
      rendererKey: 'sch',
      visible: true,
      formatter: getDay,
      handler: scheduleHandler,
      content: {
        type: CellContentType.Date,
        skipValidation: true
      },
      tdClass: 'min-w-130'
    },
    {
      key: 'deliveryScheduleTime',
      label: 'Time',
      rendererKey: 'sch',
      formatter: getTime,
      visible: true,
      content: {
        type: CellContentType.Time,
        skipValidation: true
      },
      tdClass: 'min-w-130'
    },
    {
      key: 'deliveryDriver',
      label: 'DRV ID',
      rendererKey: 'sch',
      visible: true,
      content: {
        type: CellContentType.Driver,
        locationType: LocationType.DELIVERY,
        skipValidation: true
      },
      tdClass: 'min-w-130'
    },
    {
      key: 'dropLive',
      label: 'LIVE/DRP',
      rendererKey: 'sch',
      visible: true,
      content: {
        type: CellContentType.List,
        list: dropLiveOptions
      },
      tdClass: 'min-w-130'
    },

    ...saveHeaderPart
  ],

  delivery: [
    ...detailHeaderPart,
    ...equipmentHeaderPart,
    ...infoHeaderPart,
    ...locationHeaderPart,
    ...geofenceHeaderPart,

    {
      key: 'pulloutSchedule',
      label: 'P/OUT Date',
      formatter: getDay,
      tdClass: 'min-w-130'
    },
    {
      key: 'pulloutScheduleTime',
      label: 'Time',
      rendererKey: 'sch',
      visible: true,
      tdClass: 'min-w-130'
    },
    {
      key: 'pulloutDriver',
      label: 'DRV ID',
      disabled: true,
      rendererKey: 'sch',
      visible: true,
      tdClass: 'min-w-130'
    },
    {
      key: 'deliverySchedule',
      label: 'DILV S Date',
      rendererKey: 'sch',
      visible: true,
      formatter: getDay,
      handler: scheduleHandler,
      content: {
        type: CellContentType.Date,
        skipValidation: true
      },
      tdClass: 'min-w-130'
    },
    {
      key: 'deliveryScheduleTime',
      label: 'DILV S TIME',
      rendererKey: 'sch',
      visible: true,
      formatter: getTime,
      content: {
        type: CellContentType.Time,
        skipValidation: true
      },
      tdClass: 'min-w-130'
    },
    {
      key: 'deliveryDriver',
      label: 'DRV ID',
      rendererKey: 'sch',
      visible: true,
      content: {
        type: CellContentType.Driver,
        locationType: LocationType.DELIVERY,
        skipValidation: true
      },
      tdClass: 'min-w-130'
    },
    {
      key: 'dropLive',
      label: 'LIVE/DRP',
      rendererKey: 'sch',
      visible: true,
      content: {
        type: CellContentType.List,
        list: dropLiveOptions
      },
      tdClass: 'min-w-130'
    },
    {
      key: 'pickupSchedule',
      label: 'P/U S Date',
      rendererKey: 'sch',
      visible: true,
      formatter: getDay,
      content: {
        type: CellContentType.Date,
        isDisabledBy: DropLiveType.LIVE,
        skipValidation: true
      },
      tdClass: 'min-w-130'
    },
    {
      key: 'pickupScheduleTime',
      label: 'Time',
      rendererKey: 'sch',
      visible: true,
      formatter: getTime,
      content: {
        type: CellContentType.Time,
        isDisabledBy: DropLiveType.LIVE,
        skipValidation: true
      },
      tdClass: 'min-w-130'
    },
    {
      key: 'pickupDriver',
      label: 'DRV ID',
      rendererKey: 'sch',
      visible: true,
      content: {
        type: CellContentType.Driver,
        locationType: LocationType.PICKUP,
        isDisabledBy: DropLiveType.LIVE,
        skipValidation: true
      },
      tdClass: 'min-w-130'
    },
    {
      key: 'pickupDriver',
      label: 'DRV ID',
      rendererKey: 'sch',
      visible: true,
      tdClass: 'min-w-130'
    },

    ...saveHeaderPart
  ],

  return: [
    ...detailHeaderPart,
    ...equipmentHeaderPart,
    ...infoHeaderPart,
    ...locationHeaderPart,
    ...geofenceHeaderPart,

    {
      key: 'pulloutSchedule',
      label: 'P/OUT Date',
      disabled: true,
      formatter: getDay,
      tdClass: 'min-w-130'
    },
    {
      key: 'deliverySchedule',
      label: 'DILV Date',
      rendererKey: 'sch',
      visible: true,
      formatter: getDay,
      tdClass: 'min-w-130'
    },
    {
      key: 'deliveryScheduleTime',
      label: 'Time',
      rendererKey: 'sch',
      visible: true,
      tdClass: 'min-w-130'
    },
    {
      key: 'dropLive',
      label: 'LIVE/DRP',
      disabled: true,
      rendererKey: 'sch',
      visible: true,
      tdClass: 'min-w-130'
    },
    {
      key: 'pickupSchedule',
      label: 'P/U S Date',
      rendererKey: 'sch',
      visible: true,
      formatter: getDay,
      content: {
        type: CellContentType.Date,
        isDisabledBy: DropLiveType.LIVE,
        skipValidation: true
      },
      tdClass: 'min-w-130'
    },
    {
      key: 'pickupScheduleTime',
      label: 'Time',
      rendererKey: 'sch',
      visible: true,
      formatter: getTime,
      content: {
        type: CellContentType.Time,
        isDisabledBy: DropLiveType.LIVE,
        skipValidation: true
      },
      tdClass: 'min-w-130'
    },
    {
      key: 'pickupDriver',
      label: 'DRV ID',
      rendererKey: 'sch',
      visible: true,
      content: {
        type: CellContentType.Driver,
        locationType: LocationType.PICKUP,
        isDisabledBy: DropLiveType.LIVE,
        skipValidation: true
      },
      tdClass: 'min-w-130'
    },
    {
      key: 'returnSchedule',
      label: 'RTN S Date',
      rendererKey: 'sch',
      visible: true,
      formatter: getDay,
      content: {
        type: CellContentType.Date,
        skipValidation: true
      },
      tdClass: 'min-w-130'
    },
    {
      key: 'returnScheduleTime',
      label: 'Time',
      rendererKey: 'sch',
      visible: true,
      formatter: getTime,
      content: {
        type: CellContentType.Time,
        skipValidation: true
      },
      tdClass: 'min-w-130'
    },
    {
      key: 'returnDriver',
      label: 'DRV ID',
      rendererKey: 'sch',
      visible: true,
      content: {
        type: CellContentType.Driver,
        locationType: LocationType.RETURN,
        skipValidation: true
      },
      tdClass: 'min-w-130'
    },
    {
      key: 'returnDriver',
      label: 'DRV ID',
      rendererKey: 'sch',
      visible: true,
      tdClass: 'min-w-130'
    },

    {
      key: 'save',
      label: 'Save',
      content: {
        type: CellContentType.Button,
        label: 'Save'
      },
      tdClass: 'min-w-60'
    },
    {
      key: 'complete',
      label: 'Complete',
      content: {
        type: CellContentType.Button,
        label: 'Complete'
      },
      tdClass: 'min-w-60'
    },
    {
      key: 'remarks',
      label: 'Remark',
      content: {
        type: CellContentType.Text,
        skipValidation: true
      }
    }
  ]
};
